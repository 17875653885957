import React, { useState } from 'react';
import { DownloadWrapper } from './download.style';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import logo from '../../assets/images/yushCreated.png';
import { useWindowDimensions } from '../../utils/helper';

const DownloadForm = ({ recipe, image }) => {
  const [imgSrc, setImgSrc] = useState(logo);
  const { height, width } = useWindowDimensions();

  const genImage = () => {
    htmlToImage.toPng(document.getElementById('body'), { pixelRatio: 1 }).then(function (dataUrl) {
      download(dataUrl, 'recipe.png');
    });
  };

  const showImage = () => {
    if (image) {
      var reader = new FileReader();

      reader.onload = function (e) {
        setImgSrc(e.target.result);
      };

      reader.readAsDataURL(image[0]);
    }
  };

  React.useLayoutEffect(() => {
    showImage();
    //    genImage();
  }, []);

  return (
    <DownloadWrapper>
      <button style={{ width: '100%', fontWeight: '700', borderRadius: 20 }} onClick={genImage}>
        Download
      </button>
      {recipe ? (
        <div id='body'>
          <div className='container fancy-border' id='page'>
            <header>
              <section>
                <h5 id='title'>{recipe.name}</h5> <em id='serves'>{recipe.yields} Portionen</em>
                {recipe.description ? <p>{recipe.description}</p> : null}
              </section>
            </header>
            <article>
              <section id='ingredients'>
                <h6>Zutaten</h6>
                {recipe.ingredients.map((elem, index) => {
                  return <p key={index}>{elem}</p>;
                })}
              </section>

              <section id='instructions'>
                <h6>Zubereitung</h6>
                {/* {recipe.instructions.map((elem, index) => {
                    return <p key={index}>{elem}</p>;
                  })} */}
                <p>{recipe.instructions.join(' \n')}</p>
              </section>
            </article>
            <img id='recipeImage' src={imgSrc} width={width - 360} alt='recipeImage' />
          </div>

          {/* <div className='bottom'>
            {/*            <div className='yush' style={{ position: 'absolute', bottom: 230, left: width - 80 }}>
              <p className='yushText'>created by</p> 
            <img
              src={logo}
              width='60'
              height='13'
              className='yushImg'
              style={{ left: (width - 70) / 2, position: 'relative', marginTop: 35 }}
            />
          </div> */}
        </div>
      ) : null}
    </DownloadWrapper>
  );
};

export default DownloadForm;
