import DownloadForm from '../containers/Download';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from '../theme';
import { ResetCSS } from '../assets/css/style';
import GlobalStyle, { AppWrapper, ContentWrapper } from '../containers/global.style';
import SEO from '../components/seo';

const DownloadMain = ({ location }) => {
  const recipe = location?.state?.recipe;
  const image = location?.state?.image;
  return (
    <ThemeProvider theme={theme}>
      <SEO title='Homemade food by Stailey' />
      <Modal />
      <ResetCSS />
      <GlobalStyle />
      <AppWrapper>
        <ContentWrapper>
          <DownloadForm recipe={recipe} image={image} />
        </ContentWrapper>
      </AppWrapper>
    </ThemeProvider>
  );
};

export default DownloadMain;
